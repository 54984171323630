import * as React from "react";
import styled from "styled-components";
import FormContainer from "../Form/FormContainer";
import { useFormikContext, Field, Form, Formik, FormikProps, FormikTouched } from 'formik';
import { EmailApi } from "../lib-api/apis/EmailApi";
import { ApiConfiguration } from "./apiConfiguration";
import { Step, LoanPurpose } from "./Enums"
import { trackGaEvent } from "../../GA";
import {
    Redirect,
    useHistory
} from "react-router";
import * as yup from "yup";
import { AseApi } from "../lib-api";

const phoneRegex = /^(?:(?:\+|00)49[-\s]?)?(?:(?:0\d{1,4}[-\s]?)|(?:\(\d{1,5}\)[-\s]?)?)?(?:(?:(?:\d{2,5}[-\s]?)?\d{4,9})|(?:(?:\d{2,5}[-\s]?)?\d{1,4}[-\s]?\d{1,4}[-\s]?\d{1,4}))$/;

const GradiantBackground = styled("div")({
    backgroundImage: " linear-gradient(#1D275F, #005D8C)",
    textAlign: "center",
    color: "#FCFCFC",
    paddingBottom: "75px"
});

const Headline = styled("h1")({
    fontSize: "55px",
    margin: 0,
    fontWeight: "bold",
});

const SubHeadline = styled("h2")({
    margin: 0
})


const OrangeHeadline = styled("span")({
    color: "#EC5D35"
});

export const Container = styled.div`
    background-color: #FFFFFF;
    borderRadius: 5px;
    marginTop: 50px;
    position: relative;
    marign-left:0;
    margin-right:0;
`

export const Loader = styled.div`
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 20;
    background-color: white;
    left: 0;
    padding-top: 250px;
    position: absolute;
    @media(max-width: 768px){
    img{
    position: fixed;
    left: 32%;
    }
    padding-top: 0px;
    }
`


const ResultContainer = styled.div`
   @media (max-width: 768px) {
        margin-top:0;
  }

`



const LoanFinder: React.FunctionComponent = () => {
    const histoy = useHistory();

    const routeChange = (url, amount, name) => {
        //history.pushState({ "amount:": amount }, "", "/" + url + "/?amount=" + amount);
        window.history.pushState({ "amount:": amount }, "", "/" + url + "/?amount=" + amount + "&name=" + name);
        window.location.reload(false);
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        //return <Redirect to="/${url}/?amount=${amount}"   />
    };




    return (
        <Formik
            initialValues={{
                step: Step.Amount,
                amount: 100000,
                rki: null,
                purpose: null,
                otherPurpose: null,
                otherLoan: null,
                firstName: "",
                lastName: "",
                email: null,
                phone: "",
                aseConsent: false,
                sendMail: false,
                redirect: false,
                res: null,
                hash: "",
                link: ""
            }}
            onSubmit={async (values, helpers) => {
                console.log("test")
                const api = new EmailApi(ApiConfiguration)


                const id = await api.apiEmailSignEmailAndRedirectPost({
                    name: values.firstName,
                    email: values.email,
                    notes: values.purpose,
                    loanAmount: values.amount.toString(),
                    phone: values.phone
                });

                trackGaEvent("LåneFinder", values.purpose, values.amount.toString())
            /*routeChange(values.purpose, values.amount, values.firstName)*/

                if (values.amount <= 3000) {
                    window.location.href = "/schnellkredite";
                }
                else {
                    window.location.href = "/privatdarlehen";
                }
            }}
            validationSchema={yup.object({
                firstName: yup.string()
                    .required('Fehler in der Name'),
                email: yup.string()
                    .email("måste vara ett riktigt mejl")
                    .required('Fehler in der E-Mail'),
                phone: yup.string()
                    .matches(phoneRegex, "Fehlerhafte Telefonnummer eingegeben")
                    .required('Fehler in der Telefonnummer'),
                sendMail: yup.bool()
                    .oneOf([true], "Behöver kontrolleras")
                    .required('Behöver kontrolleras')
            })}
        >
            {props => {
                const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleSubmit,
                    isValid,
                    submitCount,
                    setFieldValue
                } = props;

                function locationHasChanged() {
                    const urlParams = new URLSearchParams(window.location.search);
                    var myParam = urlParams.get('step');
                    if (myParam == null) {
                        myParam = ""
                        setFieldValue("step", Step.Amount)
                    }
                    setFieldValue("link", myParam);

                }

                window.onpopstate = locationHasChanged;
                return (
                    <div>
                        <div className="container wide">
                            {values.res == null && <FormContainer />}
                        </div>
                    </div>
                );
            }}
        </Formik>
    )
}


export default LoanFinder;