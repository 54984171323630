import * as React from "react";
import styled from "styled-components";
import { useFormikContext } from 'formik';
import { Step, LoanPurpose, Links } from "../src/Enums";
import { Headline, InnerWrapper, ButtonContainer, Next } from "./FormContainer";
import CurrencyInput from '../../FilterFlow/src/components/CurrencyInput';
import Slider, { createSliderWithTooltip } from "rc-slider";

const SliderWithTooltip = createSliderWithTooltip(Slider);

var initAmount = 150000;


export const InputContainer = styled("div")({
    marginBottom: "5px"
})

export const InputContainer2 = styled.div`
        margin-bottom: 5px;
        width: 48%;
     @media (max-width: 768px) {
        width: 100%;
      }
`


export const CheckBoxContainer = styled("div")({
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    minHeight: '33px'
   
})


export const Input = styled.input`
    border-radius: 4px;
    border: 1px solid #CCCCCC;
    height: 44px;
    font-size: 13px;
    line-height: 17px;
    padding: 0 12px;
    width: 100%;
    
  
    -moz-appearance: textfield;


    ::-webkit-autofill,
    ::-webkit-autofill:hover,
    ::-webkit-autofill:focus {
  border: 1px solid green;
  -webkit-text-fill-color: green;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
    }

    ::placeholder :-ms-input-placeholder ::-ms-input-placeholder: {
        color: #6A7B8C;
        font-weight: lighter;
    }

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    :focus {
        outline-width: 1px;
        outline-color: #2A85F7;
        color: #1D275F;
        font-weight: bold;
    }



     @media (max-width: 768px) {
         height: 42px;
      }
`;

export const InputLabel = styled("div")({
    color: '2A85F7',
    fontSize: "13px",
    fontWeight: "lighter",
    backgroundColor: "white",
    
    bottom: "63px",
    left: "6%",
    paddingLeft: "5px",
    paddingRight: "5px",
    width: "fit-content",
    borderRadius: "5px;",
    lineHeight: "16px;",
    "@media(max-width: 768px)": {
        bottom: "54px",
        left: "9%",
    }


})


export const Label = styled("label")({
    fontSize: 20,
    color: '#1D275F',
    margin: 0,
    display: 'block',
    textAlign: 'left',
    "@media (max-width: 768px)": {
        fontSize: 20,
        fontWeight: 500
    }
})

export const CurrencyWrapper = styled("div")({

    border: "1px solid #1D275F",
    color: "#1D275F",
    fontSize: "18px",
    backgroundColor: "transparent",
    borderRadius: 4,
    width: "177px",
    height: "40px",
    display: 'flex',
    alignContent: 'center',
    outline: 0,
    "&:focus": {
        backgroundColor: "#2F3971",
    },
    "&::placeholder": {
        color: "white",
        opacity: 0.4
    },
    "&:hover": {
        boxShadow: "0px 3px 6px #00000059",
    },
    "&.error": {
        border: "1px solid red",
    },

    "& input": {
        padding: "10px 15px",
        border: "none",
        background: "transparent !important",
        outline: "none",
        maxWidth: 115,
        fontWeight: 600,
    "@media (max-width: 768px)": {
        maxWidth: 100,
        }
    },
    "& span": {
        marginRight: 15,
    },
    "@media (max-width: 768px)": {
        width: "130px",

    }
})

const ErrorMSG = styled.div`
   position: absolute;
   font-size: 12px;
    color: red;
    background-color: white;
    margin-top: -28px;
    line-height: 22px;
    margin-left: 14px;
    @media (max-width: 768px) {
    margin-left: 10px;

  }
`;

export const CheckBox = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 40px;
    input{
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    span{
    position: absolute;
    border-radius: 2px;
    top: 2px;
    left: 5px;
    height: 25px;
    width: 25px;
}

`

export const Label2 = styled.label`
        font-size: 11px;
        color: #333333;
        padding: 0;
        font-weight: normal;
        left:0;
        line-height: 18px;
        width: 100%;
        text-align: left;
        opacity: 0.7;
        margin-left: 0;
        @media only screen and (max-width: 500px) {
        width: 100%;
        margin-left: 0;
        font-size: 12px;
}
`

export const CheckBoxBorder = styled("div")({
    height: "34px;",
    width: "34px;",
    position: "relative",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: "4px",
    left: "-5.2%",
    right: "0",
    zIndex: 1,
    top: 2,
    "@media(max-width: 768px)": {
        position: "absolute",
        top: 4,
        left: "-90%",
        height: "42px;",
        width: "42px;",
    }
})

const CheckLabel = styled("div")({
    fontSize: "12px",
    color: "#848BA1",
    fontWeight: "lighter",
    lineHeight: "16px",
    marginLeft: "13px",
    width: "100%",
    textAlign: "left",
    marginTop: "5px",
    "@media(max-width: 768px)": {
        padding: "0px 15px"

    }
})

const CheckboxContainer = styled("div")({
    position: "relative",
    marginBottom: 4,
    display: "flex",
    justifyContent: 'space-between',
    width: "100%",
    margin: "14px 3px"
})


const ErrorMSGMail = styled("div")({
    postition: "absolute",
    fontSize: "14px",
    color: "red"
})

export const Spacer = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "-15px"
})

export const ErrorMessage = styled("div")({
    backgroundColor: "#F6E7C3",
    borderLeft: "5px solid #EC5D35",
    color: "#333",
    padding: "5px 15px",
    textAlign: "left",
    marginBottom: 15,
})

export const HelperText = styled("p")({
    fontSize: 13,
    color: "#1D275F",
    opacity: 0.7,
    marginBottom: 20,
    marginTop: 0
})


const StepOne: React.FunctionComponent = () => {
    const { values, setFieldValue, submitForm, handleChange, errors, touched, handleBlur } = useFormikContext<any>();
    const [isDisabled, setIsDisabled] = React.useState(false)
    let min = 100
    let max = 300000

    var params = window.location.search;
    var regex = /((&|\?)step=)[^&]*/gi;
    var mark;
    function getStyles(errors, touched, field, text) {
        if (errors && touched) {
            return {
                border: '1px solid red'
            }
        }
        if (text == "name") {
            if (field != "") {
                return {
                    border: '1px solid #2A85F7'
                }
            }
        }

        if (text == "email") {
            if (field != null) {
                return {
                    border: '1px solid #2A85F7'
                }
            }
        }
        if (text == "phone") {
            if (field != "") {
                return {
                    border: '1px solid #2A85F7'
                }
            }
        }
    }

    function isInvis(boolean) {
            return {
                opacity: '0'
            }
    }

    const [loanAmount, setLoanAmount] = React.useState(initAmount);
    function getValue(e) {
        var amount = parseInt(e.replaceAll(".", ""));
        if (amount == NaN) {
            setLoanAmount(10000);
        }

        else if (amount >= 10000 && amount <= 500000) {
            setLoanAmount(amount);
            setFieldValue("amount", amount);
        }

        else if (amount > 500000) {
            setLoanAmount(500000);
            setFieldValue("amount", 500000);
        }
    }
    

    function selectLoanAmount(loanAmount) {
        if (params != "") {
            params = params.replace(regex, "$1" + Links.RKI);
            if (!params.includes("step"))
                mark = "&step=" + Links.RKI;
            else
                mark = "";
        }
        else {
            mark = "?step=" + Links.RKI;
        }
        setFieldValue("amount", loanAmount)
        setFieldValue("step", Step.RKI)
        var stepQuery = window.location.protocol + "//" + window.location.host + window.location.pathname + params + mark;
        window.history.pushState({ path: stepQuery }, '', stepQuery)
        setFieldValue("link", Links.RKI);
        window.scrollTo(0, 0);

    }

    function verify(field, regex) {
        let email = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
        let phone = /(\(?([\d \-\)\–\+\/\(]+){6,}\)?([ .\-–\/]?)([\d]+))/g
        let error;


        if (regex == "email") {
            if (!email.test(field) && field != "") {
                console.log("Error happening email")
                return error
            }
        } else if (regex == "phone" && field != "") {
            console.log("Phone number test", phone.test(field), field)
            if (phone.test(field) && field != "") {
                console.log("Error happening phone")
                return error
            }
        }
        return true
    }

    function handleSubmit(e) {
        if (Object.entries(errors).length === 0) {
            console.log("Disabled")
            setIsDisabled(true)
        }
        console.log("Is submitting")
        submitForm();
    }

    function ThousandSeperator(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    return (
        <InnerWrapper>
            <Headline>Wie viel Geld möchten Sie leihen?</Headline>
            <Spacer>
                <Label>Kreditbetrag:</Label>
                <CurrencyWrapper>
                    <CurrencyInput maskOptions inputMode="numberic" step="1000" min={min} max={max} onChange={(event) => getValue(event.target.value)} value={values.amount}></CurrencyInput>
                    <span className="kr">€</span>
                </CurrencyWrapper>
            </Spacer>
            {errors.amount && touched.amount ? (<ErrorMessage>{errors.amount}</ErrorMessage>) : null}
            <SliderWithTooltip
                name="amount"
                tipProps={{ defaultVisible: false, visible: false }}
                step={100}
                min={min}
                max={max}
                defaultValue={values.amount}
                value={values.amount}
                onChange={(e) => setFieldValue("amount", e)}
                onAfterChange={(e) => console.log("After change:", e)}
                className={values.amount == 100000 ? "slider-ripple" : ""}

            />
            <Spacer><HelperText>{ThousandSeperator(min)}</HelperText><HelperText>{ThousandSeperator(max)}</HelperText></Spacer>

            <InputContainer>
                <Input className="usericon" placeholder="Name" type="string" style={getStyles(errors.firstName, touched.firstName, values.firstName, "firstName")} name="firstName" id="firstName" onChange={handleChange} />
                <InputLabel style={isInvis(values.firstName == "" ? true : false)}>{(values.firstName == "tom" ? "" : "Namn")}</InputLabel>
            </InputContainer>
            <div className="userinfo">
                <InputContainer2>
                    <Input className="emailicon" placeholder="Email" type="string" style={getStyles(errors.email, touched.email, values.email, "email")} name="email" id="email" onChange={handleChange} onBlur={handleBlur} />
                    <InputLabel style={isInvis(values.email == "" || values.email == null ? true : false)}>{(values.email == "tom" ? null : "Email")}</InputLabel>
                    {errors.email && touched.email ? <ErrorMSG>muss eine echte E-Mail sein</ErrorMSG> : null}
                </InputContainer2>
                <InputContainer2>
                    <Input className="phoneicon" placeholder="Telefonnummer" type="tel" style={getStyles(errors.phone, touched.phone, values.phone, "phone")} name="phone" id="phone" onChange={handleChange} onBlur={handleBlur} />
                    <InputLabel style={isInvis(values.phone == "" || values.phone == null ? true : false)}>{(values.phone == "tom" ? null : "Telefonnummer")}</InputLabel>
                    {errors.phone && touched.phone ? <ErrorMSG>{errors.phone}</ErrorMSG> : null}
                </InputContainer2>
            </div>
           
            <CheckBoxContainer>
                <CheckBox className="check">
                    <input value={values.sendMail} name="sendMail" id="sendMail" type="checkbox" onChange={handleChange} />
                    <span className={errors.sendMail && touched.sendMail ? "checkmark errorborder" : "checkmark"}></span>
                </CheckBox>
                <Label2 className={errors.sendMail && touched.sendMail ? "errorlabel" : ""}>
                    Ich bin damit einverstanden, dass Clarafinanz.de meine persönlichen Daten verwendet, um mir per E-Mail, SMS oder Post den Clarafinanz.de-Newsletter über den Kreditmarkt, persönliche Finanzen und aktuelle Kredite und Kreditgeber, die mit Clarafinanz.de zusammenarbeiten und von Clarafinanz.de empfohlen werden, zuzusenden. Sie können den Newsletter jederzeit abbestellen, wenn Sie keine weiteren Mails von Clarafinanz.de mehr erhalten möchten. Erfahren Sie mehr über unsere Allegemeinen Geschäftsbedingungen und Datensschutzbestimmungen.
                </Label2>
            </CheckBoxContainer>


            <ButtonContainer>
                <Next className={!values.sendMail || values.name == "" || values.email == "" || values.phone == "" || !verify(values.email, "email") || !verify(values.phone, "phone") ? "col-xs-15 disabled" : "col-xs-15"} type="submit" onClick={handleSubmit} disabled={isDisabled} >Weiter zum Kreditangebot<img src="../images/arrow button.svg" className="arrow-button"/></Next>
            </ButtonContainer>
        </InnerWrapper>
    )
}

export default StepOne