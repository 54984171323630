import * as React from "react";
import styled from "styled-components";
import StepCounter from "./StepCounter";
import { useFormikContext, Field, Form, Formik, FormikProps, FormikTouched } from 'formik';
import { LoanPurpose, Step, Links } from "../src/Enums"
import { Container } from "../src/LoanFinder"
import StepOne from "./StepOne";


export function ThousandSeperator(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export const Headline = styled.h2`
    font-size: 18px;
    font-weight: bold;
    color: #1D275F;
    margin-bottom: 40px;
    padding-top: 20px;

    @media (max-width: 768px) {
        font-size: 18px;
        margin-bottom: 30px;
        text-align: center;
  }
`

const Button = styled(styled("button")({
    fontSize: "29px",
    border: "none",
    padding: "10px",

    ":focus": {
        outline: "none"
    }
}))`
    img {
        margin: 0 10px
}

   

`

const Previous = styled(Button)`
    font-size: 20px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: inherit;
    width: 100%;

    img {
        transform: rotate(180deg);
        width: 20px;
        margin-right: 20px;
    }

    @media (max-width: 768px) {
        border: 1px solid #007AB9;
        float: none;
        margin: auto;
        margin-bottom:10px;
        position: initial;
  }
`;

export const Next = styled(Button)({
    backgroundColor: '#08C177',
    width: '100 %',
    boxShadow: '0px 3px 6px #00000040',
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'center',
    borderRadius: "2px",
    color: "white",
    border: "0;",
    margin: "auto",
    height: "48px",
    float: "none",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.2s linear 0s',


    ":focus": {
        outline: "none",
        backgroundColor: '#05B56E',
    },

    ":hover": {
        outline: "none",
        backgroundColor: '#05B56E',
    },
    "@media(max-width: 768px)": {
        float: "none",
        margin: "auto",
        fontSize: "14px",


    },
    ":disabled": {
        color: "white",
        backgroundColor: "#CCCCCC",
    }
})



export const ButtonContainer = styled.div`
    padding: 10px 0;
    height: 85px;
    margin-top: 28px;

   @media (max-width: 768px) {
         height: auto;
        margin-top: 20px;
  }
`


export const LoanTypeContainer = styled.div`
    width: 33%;
    float: left;
    

    @media (max-width: 768px) {
         width: 50%;
  }

`;



export const LoanTypeButton = styled.div`
    border-radius: 5px;
    color: #0e3858;
    background: #E8F2FE;
    cursor: pointer;
    margin: 15px;
    padding: 25px 0;
    font-size: 24px;
    font-weight: 500;

    :hover {
        background: #0e3858;
        color: #fefefe;
    }

    @media (max-width: 768px) {
        padding: 15px 0;
        font-size: 16px;
        margin: 11px;
  }
`;

export const Image = styled("img")({
    width: "120px",
    height: "auto"
})

export const InnerWrapper = styled("div")({
    margin: "auto",
    width: "95%",

})

const FormStyle = styled.form`
   margin-top: -20px;
   padding: 20px;
   padding-top: 0px;
    @media (max-width: 768px) {
         height: auto;
         padding-top: 0px;
  }
        @media (max-width: 1350px) {
                padding: 20px;
                padding-top: 0;
            }
`

const Triangle = styled.div`
  width: 0; 
  height: 0; 
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 18px solid #E8F2FE;
  position: absolute;
  right: -14px;
  bottom: 30%;

`



export const FormCon = styled.div`
@media (max-width: 800px) {
         width: 100%;
  }
`


const FormContainer: React.FunctionComponent = () => {
    const { values, setFieldValue, handleSubmit, isSubmitting, submitForm } = useFormikContext<any>();
    function PreviousStep() {
        setFieldValue("step", values.step - 1)
    }

    function getSpeech(currentStep) {
        switch (currentStep) {
            case Step.Amount:
                return "Udfyld få oplysninger - det tager under 1 minut";
            case Step.RKI:
                return "Jeg har brug for lidt flere oplysninger";
            case Step.Purpose:
                return "Jeg har næsten alle de oplysninger som jeg skal bruge";
            case Step.Information:
                return "Jeg har allerede fundet over 4 lånetilbud til dig"
        }
    }

    return (
        <FormCon>
            <div className="loan-selector">
                <Container className="innerBox">
                    <div className="wide">
                        <FormStyle onSubmit={handleSubmit} className=" boxForm">

                            {values.link == Links.Start && <StepOne />}

                        </FormStyle>
                        <div className="col-lg-1"></div>
                    </div>
                </Container>
            </div>
        </FormCon>
    )
}

//<ButtonContainer className="col-lg-7 col-lg-offset-8 col-xs-15" >
//    {values.step != Step.Amount && <Previous className="col-lg-7 col-xs-15" onClick={() => PreviousStep()}><img src="images/next_blue.svg" />Forrige</Previous>}
//    {values.step == Step.Information && <Next className="col-lg-7 col-lg-offset-1 col-xs-15" type="submit" disabled={isSubmitting} onClick={submitForm}>Næste<img src="images/next_white.svg" /></Next>}
//</ButtonContainer>

export default FormContainer;