/* tslint:disable */
/* eslint-disable */
/**
 * Morebanker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface ApiAseSendInfoPostRequest {
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    phone?: string | null;
}

/**
 * 
 */
export class AseApi extends runtime.BaseAPI {

    /**
     */
    async apiAseSendInfoPostRaw(requestParameters: ApiAseSendInfoPostRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.firstName !== undefined) {
            queryParameters['first_name'] = requestParameters.firstName;
        }

        if (requestParameters.lastName !== undefined) {
            queryParameters['last_name'] = requestParameters.lastName;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.phone !== undefined) {
            queryParameters['phone'] = requestParameters.phone;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Ase/SendInfo`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAseSendInfoPost(requestParameters: ApiAseSendInfoPostRequest): Promise<void> {
        await this.apiAseSendInfoPostRaw(requestParameters);
    }

}
