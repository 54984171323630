export enum Step {
    Amount = 1,
    RKI = 2,
    Purpose = 3,
    Information = 4
}

export enum LoanPurpose {
    Shopping,
    Holiday,
    Car,
    Renovation,
    Debt,
    Other
}

export enum Links {
    RKI = "rki",
    Member = "member",
    Why = "grundlag",
    Info = "information",
    Start = ""


}