import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import * as React from "react";
import * as ReactDOM from "react-dom";
import LoanCalculator from "./LoanFinder"


const LoanFinder = document.getElementsByTagName("LoanFinder");
if (LoanFinder.length > 0) {
    const element = LoanFinder[0];
    ReactDOM.render(
        <LoanCalculator />,
        element
    );
}