declare var window: {
    dataLayer: any[];
};

export function trackGaEvent(
    category: string,
    action: string,
    label?: string,
    value?: any
)
{
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: "GAEvent",
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        eventValue: value
    });
}


export function trackGaEmailEvent(
    lån: string,

) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'EmailPopup',
        Lån: lån,
    });
}