/* tslint:disable */
/* eslint-disable */
/**
 * Morebanker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface ApiEmailSignEmailAndRedirectPostRequest {
    name?: string | null;
    email?: string | null;
    notes?: string | null;
    loanAmount?: string | null;
    phone?: string | null;
}

/**
 * 
 */
export class EmailApi extends runtime.BaseAPI {

    /**
     */
    async apiEmailSignEmailAndRedirectPostRaw(requestParameters: ApiEmailSignEmailAndRedirectPostRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.notes !== undefined) {
            queryParameters['notes'] = requestParameters.notes;
        }

        if (requestParameters.loanAmount !== undefined) {
            queryParameters['loanAmount'] = requestParameters.loanAmount;
        }

        if (requestParameters.phone !== undefined) {
            queryParameters['phone'] = requestParameters.phone;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Email/SignEmailAndRedirect`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiEmailSignEmailAndRedirectPost(requestParameters: ApiEmailSignEmailAndRedirectPostRequest): Promise<void> {
        await this.apiEmailSignEmailAndRedirectPostRaw(requestParameters);
    }

}
